import React from "react";

const Pages404 = () => {
  return (
    <main>
      <section className="flex justify-center pt-20 md:pt-28">
        This page is not available for you.
      </section>
    </main>
  );
};

export default Pages404;
