import React, { useReducer } from "react";
import Sidebar from "./Sidebar";
import { listmenu, profile } from "../../../assets/icons";
import { NavbarReducer } from "../../../store/navbar/NavbarReducer";
import {
  initialNavbarState,
  NavbarContextProvider,
} from "../../../store/navbar/NavbarContext";
import { useLocation } from "react-router-dom";

function Navbar() {
  const { pathname } = useLocation();
  const [NavbarState, NavbarDispatch] = useReducer(
    NavbarReducer,
    initialNavbarState
  );

  return (
    <NavbarContextProvider value={{ NavbarState, NavbarDispatch }}>
      <aside
        className={
          pathname !== "/login" && pathname !== "/login-gatekeeper"
            ? "flex"
            : "hidden"
        }
      >
        <Sidebar />
        <nav className="font-med fixed z-30 flex-wrap items-center justify-between px-6 md:px-12 xl:px-16 py-2 w-full md:h-20 sm:h-16 h-12 bg-black shadow ">
          <div className="w-full flex items-center justify-between h-full">
            <div className="flex items-center justify-between h-full w-1/3">
              <img
                src={listmenu}
                className="md:w-5 w-4 cursor-pointer"
                alt="menu"
                onClick={() =>
                  NavbarDispatch({
                    payload: true,
                    type: "show_sidebar",
                  })
                }
              />
            </div>

            <div className="flex items-center mx-auto w-1/3 justify-center">
              <h1 className="text-softPink mr-1.5 md:text-2xl text-base text-center">
                DEPATU
              </h1>
              <h1 className="text-white md:text-2xl text-base sm:inline hidden">
                Scanner
              </h1>
            </div>

            <div className="flex items-center justify-end w-1/3 ">
              <img
                src={profile}
                className="md:w-6 md:h-6 w-4 h-4 ml-7"
                alt=""
              />
            </div>
          </div>
        </nav>
      </aside>
    </NavbarContextProvider>
  );
}

export default Navbar;
