import React, { useContext } from "react";
import { useMutation } from "react-query";
import { ScanRequest } from "../../../apis/scan/ScanRequest";
import { cancel } from "../../../assets/icons";
import { ActionType } from "../../../store/scan/action";
import ScanContext from "../../../store/scan/context";
import Button from "../../atoms/button/Button";
import { isEmpty } from "lodash";
import Snackbar from "../../atoms/toast/Snackbar";

const ScanResultModal = () => {
  const { ScanState, ScanDispatch } = useContext(ScanContext);
  const { showScanResult } = ScanState;

  const goBack = () => {
    ScanDispatch({
      type: ActionType.ShowScanResult,
      payload: {
        code: "",
        result: {},
        status: false,
      },
    });
  };

  const mutation = useMutation(
    () => ScanRequest.scanProfile(showScanResult.code, false),
    {
      onMutate: () => {
        console.log("mutate scan profile");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (!isEmpty(data)) {
          Snackbar("#00C62E", "Scan Success");
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-full inset-0 flex justify-center items-center bg-black bg-opacity-70">
      <div className="bg-white rounded-lg cursor-default overflow-hidden h-3/4 shadow-md xl:w-2/5 lg:w-3/5 sm:w-10/12 w-11/12">
        <div className="flex justify-between items-center px-10 py-5 h-15% shadow-sm">
          <div className="text-xl xl:text-2xl flex">
            <h1>Scan</h1>
            <h1 className="text-softPink ml-1">Details</h1>
          </div>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer xl:w-6 w-5 rounded-lg"
            onClick={goBack}
          />
        </div>
        <hr />
        <div className="h-70% overflow-auto">
          <div className="px-10 py-7 flex items-center">
            <img
              src={showScanResult?.result.ticket_bundle.preview_image_path}
              alt="-"
              className="md:w-1/5 w-1/6"
            />
            <h1 className="md:text-xl text-base ml-10">
              {showScanResult?.result.ticket_bundle.name}
            </h1>
          </div>
          <hr />
          <section className="py-3 md:text-base text-sm">
            <div className="px-10 py-1 flex items-center">
              <div className="w-1/2">
                <h1>Buyer Username</h1>
              </div>
              <div className="w-1/2">
                <h1>: {showScanResult?.result.username}</h1>
              </div>
            </div>
            <div className="px-10 py-1 flex items-start">
              <div className="w-1/2">
                <h1>Quantity</h1>
              </div>
              <div className="w-1/2">
                {isEmpty(showScanResult.result.scan_count_detail) ? (
                  <h1>: -</h1>
                ) : (
                  Object.values(showScanResult.result.scan_count_detail).map(
                    (el: any, i: number) => (
                      <h1
                        className={"text-start " + (i !== 0 && "mt-1")}
                        key={i}
                      >
                        : {el.scan_count} ticket, ({el.sub_title})
                      </h1>
                    )
                  )
                )}
              </div>
            </div>
            <div className="px-10 py-1 flex items-start">
              <div className="w-1/2">
                <h1>Ticket Reward</h1>
              </div>
              <div className="w-1/2">
                {isEmpty(showScanResult.result.result_grants) ? (
                  <h1>: -</h1>
                ) : (
                  showScanResult.result.result_grants.map(
                    (el: any, i: number) => (
                      <h1
                        className={"text-start " + (i !== 0 && "mt-1")}
                        key={i}
                      >
                        : {el.quantity}, {el.campaign_name}
                      </h1>
                    )
                  )
                )}
              </div>
            </div>
          </section>
        </div>

        <div className="flex justify-center items-center pt-2 outline-none h-15% shadow-inner">
          <Button
            isSubmitting={mutation.isLoading}
            loadColor={"white"}
            height={"h-10"}
            type={"button"}
            color={"bg-softPink"}
            hoverColor={"bg-red-300"}
            text={"Use Ticket"}
            textColor={"text-white"}
            handleClick={() => mutation.mutate()}
          />
        </div>
      </div>
    </div>
  );
};

export default ScanResultModal;
