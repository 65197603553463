import React, { useContext, useState } from "react";
import QrReader from "react-qr-reader";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ScanRequest } from "../../../apis/scan/ScanRequest";
import { ActionType } from "../../../store/scan/action";
import ScanContext from "../../../store/scan/context";
import Snackbar from "../../atoms/toast/Snackbar";
import { isEmpty } from "lodash";
import SubNavbar from "../../molecules/navbar/SubNavbar";
import ScanResultModal from "./ScanResultModal";
import CircularLoad from "../../atoms/loading/CircularLoad";

const ScanProfile = () => {
  const navigate = useNavigate();
  const { ScanDispatch, ScanState } = useContext(ScanContext);
  const { showScanResult } = ScanState;
  const [code, setCode] = useState<string>("");

  const handleErrorWebCam = (error: any) => {
    Snackbar("#DF5354", error);
  };

  const handleScanWebCam = (result: string | null) => {
    if (result && !mutation.isLoading && !isEmpty(showScanResult)) {
      setCode(result);
      mutation.mutate(result);
      document.body.classList["add"]("h-screen", "overflow-hidden");
    }
  };

  const mutation = useMutation(
    (code: string) => ScanRequest.scanProfile(code, true),
    {
      onMutate: () => {
        console.log("mutate scan profile");
      },
      onSettled: async (data: any) => {
        if (data) {
          ScanDispatch({
            type: ActionType.ShowScanResult,
            payload: {
              status: true,
              code: code,
              result: data,
            },
          });
          setCode("");
        } else {
          document.body.classList["remove"]("h-screen", "overflow-hidden");
          setCode("");
        }
      },
    }
  );

  return (
    <div className="h-screen overflow-hidden">
      {mutation.isLoading && (
        <div className="fixed top-0 left-0 overflow-hidden h-screen z-50 w-full bg-black bg-opacity-70 flex justify-center items-center m-auto">
          <CircularLoad color={"#ed4846"} height={50} width={50} />
        </div>
      )}
      <div className="fixed top-0 z-40">
        <SubNavbar
          title1="Scan"
          title2="Profile"
          onClick={() => navigate(`/scan`)}
        />
      </div>
      {showScanResult.status && <ScanResultModal />}

      <div className="flex flex-col h-screen max-h-screen items-center justify-center">
        <div className="xl:w-1/2 lg:w-10/12 w-full h-full">
          <QrReader
            delay={300}
            style={{ width: "100%" }}
            onError={handleErrorWebCam}
            onScan={handleScanWebCam}
          />
        </div>
      </div>
    </div>
  );
};

export default ScanProfile;
