import axios from "axios";

class AxiosInstance {
  static Celestine = () => {
    let value = localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_CELESTINE_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
        version: "23.6",
      },
    });
  };

  static Mercury = () => {
    let value = localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_MERCURY_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
      },
    });
  };

  static Dicket = () => {
    let value = localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_DICKET_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
      },
    });
  };

  static Scan = () => {
    let value = localStorage.getItem("AUTH_TOKEN");
    return axios.create({
      baseURL: process.env.REACT_APP_SCAN_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${value}`,
      },
    });
  };
}

export default AxiosInstance;
