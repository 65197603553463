import AxiosInstance from "../../config/AxiosInstance";

class AuthRequest {
  static Login = async (values: { username: string; password: string }) => {
    try {
      const data: any = await AxiosInstance.Mercury().post("retail/auth/signin", {
        username: values.username,
        password: values.password,
      });
      const token = await data?.data?.token;
      const role = await data?.data?.retail_user.retail_role_id;
      return { token, role };
    } catch (error: any) {
      const err = error.response.data.errors;
      return { err };
    }
  };

  static LoginCelestine = async (values: { username: string; password: string }) => {
    try {
      const data: any = await AxiosInstance.Celestine().post("admin/auth/signin", {
        username: values.username,
        password: values.password,
      });
      const token = await data?.data?.token;
      const role = await data?.data?.user?.role?.id;
      return { token, role };
    } catch (error: any) {
      const err = error.response.data.errors;
      return { err };
    }
  };
}

export default AuthRequest;
