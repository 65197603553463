import React, { useContext } from "react";
import { logout, product } from "../../../assets/icons/Web/index";
import { cancel } from "../../../assets/icons";
import { Link, useNavigate } from "react-router-dom";
import NavbarContext from "../../../store/navbar/NavbarContext";
import jwt_decode from "jwt-decode";
import { validateRole } from "../../../utils/ValidateRole";

const Sidebar: React.FC = () => {
  const { NavbarDispatch, NavbarState } = useContext(NavbarContext);
  const { showSidebar } = NavbarState;
  const navigate = useNavigate();
  const role = localStorage.getItem("ROLE");
  const token = localStorage.getItem("AUTH_TOKEN");

  const handleShowSideBar = () => {
    NavbarDispatch({
      payload: false,
      type: "show_sidebar",
    });
  };

  const handleLogout = () => {
    handleShowSideBar();
    validateRole(role, process.env.REACT_APP_GATEKEEPER_ROLE_ID || "8")
      ? navigate("/login-gatekeeper")
      : navigate("/login");
    localStorage.clear();
  };

  const renderUsername = (token: string | null) => {
    if (token) {
      const payload: { username: string } = jwt_decode(token);
      const username =
        payload.username.charAt(0).toUpperCase() +
        payload.username.slice(1).split("_").join(" ");
      return username;
    } else {
      return "User";
    }
  };

  return (
    <div
      className={
        showSidebar && "fixed inset-0 bg-black bg-opacity-70 z-50 w-full"
      }
    >
      <nav
        className={
          "shadow-xl fixed flex flex-wrap lg:w-1/4 w-10/12 z-50 py-6 duration-500 transform ease-in-out transition h-screen bg-white overflow-auto " +
          (!showSidebar && "-translate-x-full")
        }
      >
        <div className="flex-col bg md:items-stretch md:min-h-full items-center justify-between w-full font-med">
          <div className="flex justify-between w-full md:my-1 md:px-7 px-5">
            <h1
              onClick={handleShowSideBar}
              className="md:block text-left md:text-2xl cursor-default text-base font-med"
            >
              {renderUsername(token)}
            </h1>
            <img
              src={cancel}
              alt="back"
              onClick={handleShowSideBar}
              className="md:w-6 w-4 md:h-6 h-4 cursor-pointer"
            />
          </div>
          <hr className="mt-5 mb-2 w-full" />

          {/* Navigation */}
          <div
            className={"w-full items-center flex-1 lg:px-7 px-5 lg:mt-3 mt-1"}
          >
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {validateRole(role, "3") && (
                <li className="py-2">
                  <Link
                    onClick={handleShowSideBar}
                    className={
                      "flex flex-row md:text-lg text-sm font-medium items-center  " +
                      (window.location.href.indexOf("/scan") !== -1
                        ? "text-black hover:text-gray-300"
                        : "text-gray-600 hover:text-gray-300")
                    }
                    to="/scan"
                  >
                    <img
                      src={product}
                      className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                      alt="icon"
                    />
                    Scan History
                  </Link>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </li>
              )}
              {validateRole(
                role,
                process.env.REACT_APP_GATEKEEPER_ROLE_ID || "8"
              ) && (
                <li className="py-2">
                  <Link
                    onClick={handleShowSideBar}
                    className={
                      "flex flex-row md:text-lg text-sm font-medium items-center  " +
                      (window.location.href.indexOf("/scan-bracelet") !== -1
                        ? "text-black hover:text-gray-300"
                        : "text-gray-600 hover:text-gray-300")
                    }
                    to="/scan-bracelet"
                  >
                    <img
                      src={product}
                      className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                      alt="icon"
                    />
                    Scan Profile
                  </Link>
                  <hr className="md:my-5 my-3 md:min-w-full" />
                </li>
              )}
              <li className="py-2">
                <h1
                  onClick={handleLogout}
                  className={
                    "flex flex-row md:text-lg text-sm font-medium items-center cursor-pointer  " +
                    (window.location.href.indexOf("/home") !== -1
                      ? "text-black hover:text-gray-300"
                      : "text-gray-600 hover:text-gray-300")
                  }
                >
                  <img
                    src={logout}
                    className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                    alt="icon"
                  />{" "}
                  Logout
                </h1>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
