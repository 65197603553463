export const optRoutesScan = [
  {
    id: 1,
    path: "/home",
    name: "Home",
  },
  {
    id: 2,
    path: "/scan",
    name: "Scan History",
  },
];
