import { ActionType, ScanAction } from "./action";
import { IScanState } from "./state";

export const ScanReducer = (state: IScanState, action: ScanAction) => {
  switch (action.type) {
    case ActionType.ShowScanResult:
      let showScanResult = action.payload;
      document.body.classList[showScanResult.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showScanResult };
    case ActionType.ShowScanBracelet:
      let showScanBracelet = action.payload;
      document.body.classList[showScanBracelet.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showScanBracelet };
    case ActionType.ShowScanTicket:
      let showScanTicket = action.payload;
      document.body.classList[showScanTicket.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showScanTicket };
    case ActionType.ShowNoTicket:
      let showNoTicket = action.payload;
      document.body.classList[showNoTicket.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showNoTicket };
  }
};
