import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showPass, unshowPass } from "../../assets/icons/Web";
import { logo } from "../../assets/icons";
import Backdrop from "./login/Backdrop";
import Input from "../../components/atoms/input/Input";
import Snackbar from "../../components/atoms/toast/Snackbar";
import { useMutation } from "react-query";
import AuthRequest from "../../apis/Auth/AuthRequest";
import Button from "../../components/atoms/button/Button";

function LoginCelestine() {
  const navigate = useNavigate();
  const [passType, setPassType] = useState<string>("password");

  const { handleSubmit, handleChange, errors, setErrors, touched, values } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: Yup.object({
        username: Yup.string().required("Required"),
        password: Yup.string()
          .required("Required!")
          .min(6, "The password must be of minimum 6 characters!"),
      }),
      onSubmit: async (values) => {
        await mutation.mutate(values);
      },
    });

  const setLocalStorage = (token: string, role: string) => {
    localStorage.setItem("AUTH_TOKEN", token);
    localStorage.setItem("ROLE", role);
  };

  const mutation = useMutation(
    (values: any) => AuthRequest.LoginCelestine(values),
    {
      onMutate: () => {
        console.log("mutate login");
      },
      onSettled: async (data: any) => {
        if (data.err) {
          setErrors({ password: data.err });
        } else {
          document.body.classList.remove("h-screen", "overflow-hidden");
          await setLocalStorage(data.token, data.role);
          await navigate("/scan-bracelet");
          Snackbar("#00C62E", "Login Successfully");
        }
      },
    }
  );

  const handleVisibleType = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  const goToLoginAdmin = () => {
    navigate("/login");
  };

  return (
    <Fragment>
      <Backdrop active={true} />
      <div
        data-aos-duration="2000"
        data-aos="fade-down"
        className="fixed inset-0 z-30 w-full h-scren flex items-center justify-center"
      >
        <div className="flex flex-col bg-white rounded-md md:w-Wlogin md:h-Hlogin w-4/5 shadow-xl transform-gpu">
          <form onSubmit={handleSubmit}>
            <div className="md:mt-7 mt-8 md:mb-8 mb-8 flex flex-col justify-center items-center">
              <img
                onClick={goToLoginAdmin}
                alt="-"
                src={logo}
                className="md:w-12 w-10"
              />
              <div className="flex md:mt-4 mt-3">
                <h1 className="text-softPink md:text-xl text-lg">DEPATU</h1>
                <h1 className="md:text-xl text-lg ml-2">Gatekeeper</h1>
              </div>
            </div>

            <div className="md:mx-14 mx-8 mb-5">
              <div>
                <h1 className="md:text-sm text-xs text-inputColor">Username</h1>
                <Input
                  type={"text"}
                  placeholder={"username"}
                  value={values.username}
                  name={"username"}
                  handleChange={handleChange}
                />
                {touched.username && errors.username ? (
                  <h1 className="text-xs font-rom text-softPink -mt-2 mb-2">
                    {errors.username}
                  </h1>
                ) : null}
              </div>
              <div>
                <h1 className="md:text-sm text-xs text-inputColor">Password</h1>
                <div className="md:h-10 h-8 rounded-md border-2 my-2 border-softGray flex justify-between">
                  <input
                    type={passType}
                    placeholder="password"
                    className="md:text-sm font-rom text-xs px-3 w-full mr-5 focus:outline-none"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {passType === "password" ? (
                    <img
                      src={showPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  ) : (
                    <img
                      src={unshowPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  )}
                </div>
                {touched.password && errors.password ? (
                  <h1 className="text-xs font-rom text-softPink -mt-1 mb-1">
                    {errors.password}
                  </h1>
                ) : null}
              </div>
            </div>
            <div
              className={
                "flex justify-center md:mb-0 mb-5 " +
                (errors.password && errors.username ? "" : "mt-6")
              }
            >
              <Button
                isSubmitting={mutation.isLoading}
                loadColor={"white"}
                type={"submit"}
                color={"bg-softPink"}
                hoverColor={"bg-red-300"}
                text={"Login"}
                textColor={"text-white"}
                width="w-2/5"
                height="h-10"
              />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default LoginCelestine;
