import React, { useContext } from "react";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { ActionType } from "../../../store/scan/action";
import ScanContext from "../../../store/scan/context";
import Snackbar from "../../atoms/toast/Snackbar";
import SubNavbar from "../../molecules/navbar/SubNavbar";
import ScanTicketModal from "./ScanTicketModal";

const ScanTicket = () => {
  const navigate = useNavigate();
  const { ScanDispatch, ScanState } = useContext(ScanContext);
  const { showScanTicket } = ScanState;

  const handleErrorWebCam = (error: any) => {
    Snackbar("#DF5354", error);
  };

  const handleScanWebCam = (result: any) => {
    if (result) {
      ScanDispatch({
        type: ActionType.ShowScanTicket,
        payload: {
          status: true,
          code: result,
        },
      });
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="fixed top-0 z-40">
        <SubNavbar
          title1="Scan"
          title2="Ticket"
          onClick={() => navigate(`/scan`)}
        />
      </div>
      {showScanTicket.status && <ScanTicketModal />}
      <div className="flex flex-col h-screen max-h-screen items-center justify-center">
        <div className="xl:w-1/2 lg:w-10/12 w-full h-full">
          <QrReader
            delay={300}
            style={{ width: "100%" }}
            onError={handleErrorWebCam}
            onScan={handleScanWebCam}
          />
        </div>
      </div>
    </div>
  );
};

export default ScanTicket;
