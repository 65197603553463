import { useContext } from "react";
import { useMutation } from "react-query";
import { ScanRequest } from "../../../apis/scan/ScanRequest";
import { cancel } from "../../../assets/icons";
import { ActionType } from "../../../store/scan/action";
import ScanContext from "../../../store/scan/context";
import Button from "../../atoms/button/Button";
import Snackbar from "../../atoms/toast/Snackbar";

const ConfirmScan = () => {
  const { ScanDispatch, ScanState } = useContext(ScanContext);
  const { showScanBracelet } = ScanState;

  const goBack = () => {
    ScanDispatch({
      type: ActionType.ShowScanBracelet,
      payload: {
        status: false,
        code: "",
      },
    });
  };

  const mutation = useMutation(
    () => ScanRequest.scanBracelet(showScanBracelet.code),
    {
      onMutate: () => {
        console.log("mutate scan profile");
      },
      onSettled: async (data: any) => {
        if (data?.status === 200) {
          Snackbar("#00C62E", "Scan Success");
          goBack();
        } else if (data.data.errors === "no_ticket") {
          const error = data?.data?.errors;
          Snackbar("#ed4846", error?.split('_').join(' '));
          ScanDispatch({
            type: ActionType.ShowNoTicket,
            payload: {
              status: true,
              code: showScanBracelet.code,
            },
          });
        } else if (data.data.errors) {
          const error = data?.data?.errors;
          Snackbar("#ed4846", error);
        } else {
          Snackbar("#ed4846", "Scan failed");
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white lg:w-1/3 md:w-2/5 w-10/12 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10 pb-3">
          <div className="flex flex-col items-center">
            <h1 className="text-xl">Confirm QR Code</h1>
            <h1 className="text-base text-center w-60 text-inputColor mt-4 font-rom">
              Are you sure you want to use this Qr Code ?
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <Button
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Close"}
              textColor={"text-inputColor"}
              height={"h-10"}
            />
            <div className="mx-1.5"></div>
            <Button
              isSubmitting={mutation.isLoading}
              loadColor={"white"}
              type={"button"}
              height={"h-10"}
              handleClick={() => mutation.mutate()}
              color={"bg-softPink"}
              hoverColor={"bg-red-300"}
              text={"Continue"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmScan;
