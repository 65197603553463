import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./components/molecules/navbar/Navbar";
import LoginPage from "./pages/auth/Login";
import ScanRoutes from "./routes/scan/ScanRoutes";
import ProtectedRoutes from "./utils/Guards";
import ScanProfile from "./components/organisms/scan/ScanProfile";
import ScanBraceletRoutes from "./routes/scan_bracelet/ScanBraceletRoutes";
import LoginCelestine from "./pages/auth/LoginCelestine";
import Pages404 from "./pages/auth/404";
import { validateRole } from "./utils/ValidateRole";
import Aos from "aos";
import "aos/dist/aos.css";
import ScanTicket from "./components/organisms/scan/ScanTicket";
import ProtectedRoutesGatekeeper from "./utils/CelestineGuards";

function App() {
  const role = localStorage.getItem("ROLE");

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <div className="px-6 md:px-12 xl:px-16 md:pt-28 pt-20 font-med">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login-gatekeeper" element={<LoginCelestine />} />
          <Route path="/404" element={<Pages404 />} />
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/scan"
              element={<ScanRoutes />}
              children={[
                <Route path="qr-code-single" element={<ScanTicket />} />,
                <Route path="qr-code" element={<ScanProfile />} />,
              ]}
            />
          </Route>
          <Route element={<ProtectedRoutesGatekeeper />}>
            <Route path="/scan-bracelet" element={<ScanBraceletRoutes />} />
          </Route>
          {validateRole(role, process.env.REACT_APP_GATEKEEPER_ROLE_ID || "8") ? (
            <Route
              path="/"
              element={<Navigate replace to="/scan-bracelet" />}
            />
          ) : validateRole(role, "3") ? (
            <Route path="/" element={<Navigate replace to="/scan" />} />
          ) : (
            <Route path="/" element={<Navigate replace to="/login" />} />
          )}
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
