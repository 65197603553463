import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import ScanBracelet from "../../pages/scan_bracelet/ScanBracelet";
import {
  initialScanState,
  ScanContextProvider,
} from "../../store/scan/context";
import { ScanReducer } from "../../store/scan/Reducer";

const ScanBraceletRoutes = () => {
  const [ScanState, ScanDispatch] = useReducer(ScanReducer, initialScanState);
  return (
    <ScanContextProvider value={{ ScanState, ScanDispatch }}>
      <Routes>
        <Route path="/" element={<ScanBracelet />} />
      </Routes>
    </ScanContextProvider>
  );
};

export default ScanBraceletRoutes;
