import { Navigate, Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";
import moment from "moment";

export const useAuth = () => {
  const user = { loggedIn: localStorage.getItem("AUTH_TOKEN") };
  const decoded = jwtDecode(user.loggedIn);
  if (decoded.exp * 1000 < moment().valueOf()) {
    localStorage.clear();
    return false;
  } else {
    return true;
  }
};

export const checkRole = (accepted_role) => {
  const role = { roleId: localStorage.getItem("ROLE") };
  if (role.roleId === accepted_role) {
    return true;
  } else {
    return false;
  }
};
const ProtectedRoutesGatekeeper = () => {
  const isAuth = useAuth();
  const isValidRole = checkRole(process.env.REACT_APP_GATEKEEPER_ROLE_ID);

  if (isAuth && isValidRole) {
    return <Outlet />;
  } else if (isAuth && !isValidRole) {
    return <Navigate to="/404" />;
  }
  return <Navigate to="/login-gatekeeper" />;
};

export default ProtectedRoutesGatekeeper;
