import React, { Fragment, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Breadcrumb from "../../components/atoms/breadcrumb/Breadcrumb";
import { useLocation, useNavigate } from "react-router";
import Loading from "../../components/atoms/loading/Loading";
import { useQueries } from "../../utils/QuerySearchParams";
import Input from "../../components/atoms/input/Input";
import Button from "../../components/atoms/button/Button";
import Pagination from "../../components/atoms/paginate/Pagination";
import moment from "moment";
import { optRoutesScan } from "./helpers/list";
import { ScanRequest } from "../../apis/scan/ScanRequest";
import Snackbar from "../../components/atoms/toast/Snackbar";

const ScanHistory = () => {
  const query = useQueries(useLocation);
  const navigate = useNavigate();

  const { pathname, search, hash } = useLocation();
  const [scanFrom, setScanFrom] = useState("");
  const [scanTo, setScanTo] = useState("");
  const [notes, setNotes] = useState<any>({});
  const { data, isLoading } = useQuery(
    [
      "getAllScanHistory",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
      query.get("scan_date_from"),
      query.get("scan_date_to"),
    ],
    () => ScanRequest.getAllScanHistory(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const from = query.get("scan_date_from");
    const to = query.get("scan_date_to");
    if (from) setScanFrom(from);
    if (to) setScanTo(to);
  }, []);

  useEffect(() => {
    const from = query.get("scan_date_from");
    if (from !== null || scanFrom.length > 0) {
      let new_search =
        from !== null
          ? search.replace(
              `scan_date_from=${from}`,
              `scan_date_from=${scanFrom}`
            )
          : `${search}&&scan_date_from=${scanFrom}`;
      navigate({
        pathname,
        search: new_search,
        hash,
      });
    }
  }, [scanFrom]);

  useEffect(() => {
    const to = query.get("scan_date_to");
    if (to !== null || scanTo.length > 0) {
      let new_search =
        to !== null
          ? search.replace(`scan_date_to=${to}`, `scan_date_to=${scanTo}`)
          : `${search}&&scan_date_to=${scanTo}`;
      navigate({
        pathname,
        search: new_search,
        hash,
      });
    }
  }, [scanTo]);

  useEffect(() => {
    let tempNote: any = {};
    if (data) {
      data?.data?.data?.map(
        (el: any) => (tempNote[el.id] = { id: el.id, note: el.note })
      );
      setNotes(tempNote);
    }
  }, [data]);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (note: { id: number; note: string }) => ScanRequest.addNoteScan(note),
    {
      onMutate: () => {
        console.log("mutate add add note");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          Snackbar("#00C62E", "Add Note Completed");
          await queryClient.invalidateQueries("getAllScanHistory");
        }
      },
    }
  );

  const handleChangeNote = (e: any, id: number) => {
    const new_note = { ...notes };
    new_note[id].note = e.target.value;
    setNotes(new_note);
  };

  const SaveNote = (note: { id: number; note: string }) => {
    mutation.mutate(note);
  };
  return (
    <div className="font-med">
      <section id="top">
        <Breadcrumb data={optRoutesScan} />
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="md:mt-12 mt-10">
              <div className="md:mt-12 mt-10">
                <div className="flex items-center justify-end w-full">
                  <h1
                    onClick={() => navigate(`/scan/qr-code-single`)}
                    className="hover:text-softPink cursor-pointer inline-flex ml-5 md:text-base text-sm"
                  >
                    Scan Ticket
                  </h1>

                  <h1
                    onClick={() => navigate(`/scan/qr-code`)}
                    className="hover:text-softPink cursor-pointer inline-flex ml-5 md:text-base text-sm"
                  >
                    Scan Profile
                  </h1>
                </div>

                <hr className="my-3" />
                <div className="md:w-1/3 w-full flex mt-5">
                  <div className="w-1/2">
                    <h1 className="md:text-base text-sm">Scan Date (From)</h1>
                    <Input
                      type={"date"}
                      name={"scan_date_from"}
                      placeholder={"Place holder"}
                      value={scanFrom}
                      handleChange={(e: any) => setScanFrom(e.target.value)}
                    />
                  </div>
                  <div className="w-1/2 md:ml-5 ml-3">
                    <h1 className="md:text-base text-sm">Scan Date (to)</h1>
                    <Input
                      type={"date"}
                      name={"scan_date_to"}
                      placeholder={"Place holder"}
                      value={scanTo}
                      handleChange={(e: any) => setScanTo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <section>
              {!data?.data?.data?.length ? (
                <div className="flex justify-center my-20 md:text-lg text-base">
                  <h1>Empty Data</h1>
                </div>
              ) : (
                <>
                  <div className="my-7">
                    {data?.data?.data?.map((el: any, i: number) => (
                      <section key={el.id}>
                        <div className="w-full my-5 items-center px-5 flex justify-between">
                          <div className="w-full">
                            <div className="flex justify-between w-full">
                              <h1 className="md:text-base text-sm">
                                {el.label || `scan-#${i + 1}`}
                              </h1>
                              <h1 className="md:text-base text-sm font-nor">
                                {moment(el.used_at).format("ll hh:mm a")}
                              </h1>
                            </div>
                            <h1 className="md:text-base text-sm font-nor">
                              Customer : {el.username || "-"}
                            </h1>
                            <div className="flex items-center w-full">
                              <h1 className="mr-5">Notes:</h1>
                              <div className="w-full flex items-center">
                                <div className="md:w-11/12 w-10/12">
                                  <Input
                                    type={"text"}
                                    name={"notes"}
                                    value={
                                      notes[el.id]?.id === el.id
                                        ? notes[el.id]?.note
                                        : ""
                                    }
                                    handleChange={(e: any) =>
                                      handleChangeNote(e, el.id)
                                    }
                                  />
                                </div>
                                <div className="md:w-1/12 w-2/12 bg-slate-800">
                                  <Button
                                    loadColor={"white"}
                                    type={"button"}
                                    isSubmitting={
                                      mutation.variables?.id === el.id &&
                                      mutation.isLoading
                                    }
                                    handleClick={() => SaveNote(notes[el.id])}
                                    color={"bg-softPink"}
                                    hoverColor={"opacity-70"}
                                    text={"Save"}
                                    height={'md:h-10 h-8'}
                                    textColor={"text-white"}
                                    rounded={"rounded-none"}
                                    width="w-full"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </section>
                    ))}
                  </div>
                  <Pagination
                    length={data?.data?.data?.length}
                    total={data?.data?.meta?.total}
                    isTable={false}
                  />
                </>
              )}
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default ScanHistory;
