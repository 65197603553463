import { createContext } from "react";
import { IinitialNavbarState, INavbarContext } from "./interfaces";

export const initialNavbarState: IinitialNavbarState = {
  showSidebar: false,
  dropdownTransaction: false,
  dropdownProduct: false
};

const NavbarContext = createContext<INavbarContext>({
  NavbarState: initialNavbarState,
  NavbarDispatch: () => {},
});

export const NavbarContextProvider = NavbarContext.Provider;
export const NavbarContextConsumer = NavbarContext.Consumer;

export default NavbarContext;
