import { createContext } from "react";
import { IScanContext, IScanState } from "./state";

export const initialScanState: IScanState = {
  showScanResult: {
    status: false,
    code: "",
    result: "",
  },
  showScanBracelet: {
    status: false,
    code: "",
  },
  showScanTicket: {
    status: false,
    code: "",
  },
  showNoTicket: {
    status: false,
    code: "",
  },
};

const ScanContext = createContext<IScanContext>({
  ScanState: initialScanState,
  ScanDispatch: () => undefined,
});

export const ScanContextProvider = ScanContext.Provider;
export const ScanContextConsumer = ScanContext.Consumer;

export default ScanContext;
