import React, { useReducer } from "react";
import { Route, Routes } from "react-router";
import ScanProfile from "../../components/organisms/scan/ScanProfile";
import ScanTicket from "../../components/organisms/scan/ScanTicket";
import ScanHistory from "../../pages/scan/ScanHistory";
import {
  initialScanState,
  ScanContextProvider,
} from "../../store/scan/context";
import { ScanReducer } from "../../store/scan/Reducer";

const ScanRoutes = () => {
  const [ScanState, ScanDispatch] = useReducer(ScanReducer, initialScanState);

  return (
    <ScanContextProvider value={{ ScanState, ScanDispatch }}>
      <Routes>
        <Route path="/" element={<ScanHistory />} />
        <Route path="/qr-code-single" element={<ScanTicket />} />
        <Route path="/qr-code" element={<ScanProfile />} />,
      </Routes>
    </ScanContextProvider>
  );
};

export default ScanRoutes;
