import React, { useContext } from "react";
import QrReader from "react-qr-reader";
import { ActionType } from "../../store/scan/action";
import ScanContext from "../../store/scan/context";
import Snackbar from "../../components/atoms/toast/Snackbar";
import ConfirmScan from "../../components/organisms/scan_bracelet/ConfirmScan";
import NoTicketModal from "../../components/organisms/scan_bracelet/NoticketModal";

const ScanBracelet = () => {
  const { ScanDispatch, ScanState } = useContext(ScanContext);
  const { showScanBracelet, showNoTicket } = ScanState;

  const handleErrorWebCam = (error: any) => {
    Snackbar("#DF5354", error);
  };

  const handleScanWebCam = (result: string | null) => {
    if (result) {
      ScanDispatch({
        type: ActionType.ShowScanBracelet,
        payload: {
          status: true,
          code: result,
        },
      });
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      {showScanBracelet.status && <ConfirmScan />}
      {showNoTicket.status && <NoTicketModal />}
      <div className="flex flex-col h-screen max-h-screen items-center justify-center">
        <div className="xl:w-1/2 lg:w-10/12 w-full h-full">
          <QrReader
            delay={300}
            style={{ width: "100%" }}
            onError={handleErrorWebCam}
            onScan={handleScanWebCam}
          />
        </div>
      </div>
    </div>
  );
};

export default ScanBracelet;
