import { cancel } from "../../../assets/icons";

export interface SubNavbarProps {
  title1: string;
  title2: string;
  onClick: () => void;
}

const SubNavbar: React.FC<SubNavbarProps> = ({ title1, title2, onClick }) => {
  return (
    <aside className="absolute top-0">
      <nav className="fixed z-40 font-med flex flex-wrap items-center justify-between md:px-20 px-6 py-2 w-full left-0 md:h-20 h-14 bg-black shadow">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-1/3"></div>

          <div className="flex items-center mx-auto w-1/3 justify-center">
            <h1 className="text-softPink md:mr-2.5 mr-1 md:text-2xl text-base text-center">
              {title1}
            </h1>
            <h1 className="text-white md:text-2xl text-base">{title2}</h1>
          </div>

          <div className="flex items-center justify-end w-1/3 ">
            <img
              src={cancel}
              onClick={onClick}
              className="md:w-5 w-3 cursor-pointer"
              alt="back"
            />
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default SubNavbar;
