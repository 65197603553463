export const NavbarReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_sidebar":
      document.body.classList[action.payload ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSidebar: action.payload };
    case "drop_transaction":
      if (state.dropdownTransaction) {
        return { ...state, dropdownTransaction: false };
      } else {
        return { ...state, dropdownTransaction: true };
      }
    case "drop_product":
      if (state.dropdownProduct) {
        return { ...state, dropdownProduct: false };
      } else {
        return { ...state, dropdownProduct: true };
      }
  }
};
