import Snackbar from "../../components/atoms/toast/Snackbar";
import AxiosInstance from "../../config/AxiosInstance";

export class ScanRequest {
  static scanProfile = async (code: string | undefined, simulated: boolean) => {
    try {
      const result = await AxiosInstance.Dicket().post(
        `admin/ticket/scan_profile_qr/`,
        {
          code,
          simulated: simulated,
        }
      );
      const data = result?.data?.data;
      return data;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        Snackbar(
          "#ed4846",
          `${Object.keys(error.response.data.errors)[0]}: ${
            Object.values(error.response.data.errors)[0]
          }`
        );
      } else {
        Snackbar("#ed4846", "Something went wrong");
      }
    }
  };

  static scanBracelet = async (
    code: string | undefined,
    no_ticket?: boolean
  ) => {
    try {
      const payload: any = {
        qr: code,
      };
      if (no_ticket) {
        payload["no_ticket"] = no_ticket;
      }
      const result = await AxiosInstance.Scan().post(`scan`, payload);
      return result;
    } catch (error: any) {
      const err = error.response;
      return err;
    }
  };

  static scanTicket = async (code: string) => {
    try {
      const result = await AxiosInstance.Dicket().post("/admin/ticket/scan/", {
        code,
      });
      return result;
    } catch (error: any) {
      const err = error.response;
      return err;
    }
  };

  static getAllScanHistory = async () => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await AxiosInstance.Dicket().get(`admin/usage/`, {
        params: {
          used_at__isnull: false,
          used_at_after: query.get("scan_date_from"),
          used_at_before: query.get("scan_date_to"),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static addNoteScan = async (note: { id: number; note: string }) => {
    try {
      const result = await AxiosInstance.Dicket().patch(
        `/admin/usage/${note.id}/`,
        {
          note: note.note || null,
        }
      );
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        Snackbar(
          "#ed4846",
          `${
            Object.keys(error.response.data.errors)[0]
          }: ${error.response.data.errors.note[0].split("_").join(" ")}`
        );
      } else {
        Snackbar("#ed4846", error.response.data.errors || "Add Note Failed");
      }
    }
  };
}
