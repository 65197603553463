export enum ActionType {
  ShowScanResult,
  ShowScanBracelet,
  ShowScanTicket,
  ShowNoTicket,
}

export interface ShowScanResult {
  type: ActionType.ShowScanResult;
  payload: {
    status: boolean;
    code: string | undefined;
    result: any;
  };
}

export interface ShowScanBracelet {
  type: ActionType.ShowScanBracelet;
  payload: {
    status: boolean;
    code: string;
  };
}

export interface ShowScanTicket {
  type: ActionType.ShowScanTicket;
  payload: {
    status: boolean;
    code: string;
  };
}

export interface ShowNoTicket {
  type: ActionType.ShowNoTicket;
  payload: {
    status: boolean;
    code: string;
  };
}

export type ScanAction =
  | ShowScanResult
  | ShowScanBracelet
  | ShowScanTicket
  | ShowNoTicket;
